<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.devices.tabs.general.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="$t('form.photo.label')">
                    <!-- media -->
                    <b-media no-body>
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="$options.filters.mediaUrl(item, 'photo', '80x80')"
                            height="80"
                          />
                        </b-link>
                        <!--/ photo -->

                        <!-- reset -->
                        <b-button
                          v-if="item.photo"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.photo = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                        <!--/ reset -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="photo"
                          type="device_photo"
                          :multiple="false"
                          @fm-selected="selectedPhoto"
                        />

                        <b-row
                          v-if="errors && errors.photo"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors.photo[0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="errors && errors['photo.type']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['photo.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                      </b-media-body>
                    </b-media>
                    <!--/ media -->
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.position.label')"
                    label-for="position"
                    :state="errors && errors.position ? false : null"
                  >
                    <b-form-input
                      :id="'position'"
                      v-model="item.position"
                      :state="errors && errors.position ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.position">
                      {{ errors.position[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <b-form-textarea
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab :title="$t('admin.devices.tabs.models.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(model, index) in item.models"
                  :id="'model' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      :label="$t('form.model.label')"
                      :label-for="'models.' + index + '.model_id'"
                      :state="errors && errors['models.' + index + '.model_id'] ? false : null"
                    >
                      <v-select
                        :id="'models.' + index + '.model_id'"
                        v-model="model.model_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="modelsOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'models.' + index + '.model_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['models.' + index + '.model_id']">
                        {{ errors['models.' + index + '.model_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      :label="$t('form.position.label')"
                      :label-for="'models.' + index + '.position'"
                      :state="errors && errors['models.' + index + '.position'] ? false : null"
                    >
                      <b-form-input
                        :id="'models.' + index + '.position'"
                        v-model="model.position"
                        :input-id="'models.' + index + '.position'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['models.' + index + '.position']">
                        {{ errors['models.' + index + '.position'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      block
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeModel(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addModel"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row v-if="errors && errors.models">
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.models[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'devices-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
      languages: [],
      modelsOptions: [],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    this.item = await this.transformData()
    await this.loadModels()
  },
  methods: {
    selectedPhoto(data) {
      this.item.photo = data
    },
    async loadModels() {
      if (Object.keys(this.modelsOptions).length) {
        return
      }

      this.$http.get('/api/admin/models', { params: { status: 'enabled', per_page: 500 } })
        .then(response => {
          this.modelsOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async onSubmit() {
      const data = {
        status: this.item.status,
        title: this.item.title,
        description: this.item.description,
        models: this.item.models,
        photo: this.item.photo,
        position: this.item.position,
      }

      this.$http.post('/api/admin/devices', data)
        .then(res => {
          router.replace({
            name: 'devices-update',
            params: { id: res.data.data.id },
            query: { ...this.$route.query, ...{ created: Math.floor(Date.now() / 1000) } },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    addModel() {
      this.item.models.push({
        position: 1,
        model_id: null,
      })
    },
    removeModel(index) {
      this.item.models.splice(index, 1)
    },
    transformData() {
      const data = {
        position: 1,
        status: 'enabled',
        photo: null,
        models: [],
        title: {},
        description: {},
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
      })

      return data
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
